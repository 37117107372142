<template>
  <div class="homepage-wrapper">

    <template v-for="widget in homepageData.widgets" v-if="homepageData?.widgets?.length > 0">
      <div :style="customWrapperStyle(widget)" :class="widget.config?.text_backdrop_class" v-if="(widget.moduleType === 'account_summary' && status === 'authenticated') || widget.moduleType !== 'account_summary'">
        <template v-if="inArray(widget.moduleType, ['slider', 'story_circle', 'best_sellers'])">
          <ClientOnly>
            <component :is="getComponent(widget.moduleType)" :payload="widget" />
          </ClientOnly>
        </template>
        <component :is="getComponent(widget.moduleType)" :payload="widget" v-else />
      </div>
    </template>

    <ClientOnly>
      <ScrollToTop />
    </ClientOnly>
  </div>
</template>

<script setup>
const {isMobile, isTablet, isDesktop } = useDevice()
const storeConfig = useStoreConfig()
const {baseConfig} = storeToRefs(storeConfig)

const { locale } = useI18n()
const {isWholesaleUser} = await useCustomer()
const { status } = useAuth()

definePageMeta({
  middleware: 'restrict',
})

useHead({
  title: baseConfig.value?.seo_title[locale.value],
  meta: [
    {name: 'description', content: baseConfig.value?.seo_description[locale.value]},
    {name: 'keywords', content: baseConfig.value?.seo_keywords[locale.value]}
  ],
})

const headers = useRequestHeaders(['cookie'])

const {data:homepageData, error} = await useAsyncData(
    `homepageData::${isWholesaleUser.value ? 1 : 0}`,
    () => $fetch('/api/homepage', {
      method: 'GET',
      headers : {
        ...headers,
        "x-lang" : locale.value,
        "x-country-code" : useCookie('countryCode').value
      },
      pick : ['widgets']
    })
)

/**
 * Anasayda bileşenlerini dinamik olarak yükler
 * @param name
 * @returns {ConcreteComponent|string|null}
 */
const getComponent = (name) => {
  if (name === 'best_sellers') {
    return resolveComponent('WidgetBestSellers')
  } else if (name === 'collection_list') {
    return resolveComponent('LazyWidgetCollectionList')
  } else if (name === 'new_products') {
    return resolveComponent('LazyWidgetNewProducts')
  } else if (name === 'custom_widget_1') {
    return resolveComponent('LazyWidgetCustomWidget')
  } else if (name === 'grid') {
    return resolveComponent('LazyWidgetGrid')
  } else if (name === 'products_list') {
    return resolveComponent('LazyWidgetProductList')
  } else if (name === 'simple_box') {
    return resolveComponent('LazyWidgetSimpleBox')
  } else if (name === 'slider') {
    return resolveComponent('WidgetSlider')
  } else if (name === 'story_circle') {
    return resolveComponent('WidgetStoryCircle')
  } else if (name === 'product_attribute_filter') {
    return resolveComponent('LazyWidgetAttributeFilter')
  } else if (name === 'count_down_date') {
    return resolveComponent('LazyWidgetCountDownDate')
  } else if (name === 'html_content') {
    return resolveComponent('LazyWidgetHtmlContent')
  } else if (name === 'account_summary') {
    return resolveComponent('LazyWidgetAccountSummary')
  } else {
    return null
  }
}

const customWrapperStyle = (props) => {
  // count_down_date hariç bıraktım çünkü modül içinde özel stil ayarlamaları mevcut
  if (props.customStyle?.background_image) {
    if (isMobile) return; // mobilde background image gösterme
    return {
      backgroundImage: `url(${props.customStyle.background_image}) !important`
    }
  } else if (props.customStyle?.background_color) {
    return {
      backgroundColor: `${props.customStyle.background_color} !important`
    }
  } else {
    return props?.customStyle;
  }
}

</script>

<style scoped>

</style>