<template>
    <div class="relative" :class="payload.customClass" >
      <div class="flex items-center space-x-4 lg:space-x-12 overflow-hidden" v-if="isLoading">
        <SkeletonProductCard v-for="i in 8" />
      </div>

      <div class="grid gap-5" :class="[gridColClass]" v-else-if="isGridView">
        <template v-for="(product, index) in products" :key="`sp-${index}`">
          <LayoutComponentProductCard :product="product" />
        </template>
      </div>

      <SwiperProduct
          :widget-title="payload.title"
          :show-navigation="!isGridView && payload?.config?.show_navigation_arrows"
          :products="products"
          :breakpoints="breakpoints"
          :loading="isLoading"
          v-else-if="products?.length"
      >
        <template v-slot:actions>
          <div class="flex md:justify-end order-3 md:order-2 md:col-auto col-span-2 flex-1 space-x-5 " v-if="payload.filters?.length > 1">
            <div
                v-for="i in payload.filters"
                :key="`bsfn-${i.id}`"
                class="hover:text-secondary hover:underline cursor-pointer font-medium"
                :class="activeFilter === i.id ? 'text-secondary underline' : 'text-primary'"
                @click="fetchProducts(i.id)"
                v-if="payload.filters?.length > 0">
              {{ i.filter_name }}
            </div>
          </div>
        </template>
      </SwiperProduct>

      <div class="flex items-center h-20" v-else>
        <ExclamationTriangleIcon class="h-5 w-5 inline-block text-red"/>
        <span class="opacity-50 pl-2">{{ $t('listing.no-result') }}</span>
      </div>

      <div class="flex justify-center mt-10" v-if="payload.config?.show_view_all_button">
        <NuxtLinkLocale
            :href="checkAndAddSlash(payload.config?.view_all_link)"
            class="btn-show-all"
        >
          {{ $t('app.view-all') }}
        </NuxtLinkLocale>
      </div>
    </div>
</template>

<script setup>
import {ExclamationTriangleIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/24/outline";
import {SwiperSlide} from "swiper/vue";
import {computed} from "vue";

const props = defineProps(['payload'])
const id = useId()

// TODO : refactor as composable
const isLoading = ref(false)
const products = ref(props.payload.content)
const activeFilter = ref(props.payload.config?.defaultCategory)
const { isDesktop, isTablet, isMobile} = useDevice();

const nagivationSettins = {
  prevEl: '#prevx_'+id,
  nextEl: '#nextx_'+id,
  disabledClass: 'opacity-75'
}

const fetchProducts = async (id) => {
  isLoading.value = true
  const headers = useRequestHeaders(['cookie'])
  const data = await $fetch('/api/widgets/products', {
    query : {
      widgetType: props.payload?.moduleType,
      defaultCategory: id,
    },
    headers : {
      ...headers
    },
  })

  activeFilter.value = id
  products.value = data

  isLoading.value = false
}

const breakpoints = computed(() => {
  return {
    '(min-width: 1080px)': {
      slides :  {
        perView: parseFloat(props.payload.config?.desktop_breakpoint) > 0 ? parseFloat(props.payload.config?.desktop_breakpoint) : 3,
        spacing: 16,
      }
    },
    '(min-width: 767px and max-width: 1079px)': {
      slides :  {
        perView: parseFloat(props.payload.config?.tablet_breakpoint) > 0 ? parseFloat(props.payload.config?.tablet_breakpoint) : 2.5,
        spacing: 16,
      }
    },
    '(max-width: 767px)': {
      slides :  {
        perView: parseFloat(props.payload.config?.mobile_breakpoint) > 0 ? parseFloat(props.payload.config?.mobile_breakpoint) : 1.5,
        spacing: 16,
      }
    },
  }
});

const style = computed(() => {
    if (props.payload?.customStyle?.background_image) {
        return {
            backgroundImage : `url(${props.payload.customStyle?.background_image})`,
            backgroundSize : '100%'
        }
    } else {
        return null;
    }
})

const enableSliderOnMobile = computed(() => props.payload.config?.enable_slider_on_mobile)

const enableSliderOnTablet = computed(() => props.payload.config?.enable_slider_on_tablet)

const isGridView = computed(() => {
  if (isMobile && products.value?.length && props.payload.config?.view_type === 'grid' && enableSliderOnMobile) {
    return false
  } else if (isTablet && products.value?.length && props.payload.config?.view_type === 'grid' && enableSliderOnTablet ) {
    return false
  } else {
    return products.value?.length && props.payload.config?.view_type === 'grid'
  }
})

const gridColClass = computed(() => {
  const config = props.payload.config
  const desktopLength = parseInt(config?.desktop_length)
  const tabletLength = parseInt(config?.tablet_length)
  const mobileLength = parseInt(config?.mobile_length)

  if(desktopLength) {
    // default class
    let className = `grid-cols-${desktopLength}`

    // Tablet sayısı belirtilmiş ancak mobil belirtilmemiş
    if (tabletLength > 0 && !mobileLength) {
      className = `grid-cols-${tabletLength} lg:grid-cols-${desktopLength}`
    }
    // mobil sayısı belirtilmiş ancak table belirtilmemiş
    else if (mobileLength > 0 && tabletLength) {
      className = `grid-cols-${mobileLength} sm:grid-cols-${desktopLength}`
    }
    // Her iki çözünürlük içinde parametre belirtilmiş
    else if (tabletLength > 0 && mobileLength > 0) {
      className = `grid-cols-${mobileLength} sm:grid-cols-${tabletLength} lg:grid-cols-${desktopLength}`
    }

    return className;
  } else {
    return 'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5';
  }
})

</script>

<style scoped>

</style>